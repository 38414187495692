import { ComponentProps, useState } from "react"
import Image from "@rasenganjs/image"
import { twMerge } from "tailwind-merge"

type ImageCardProps = {
  data: {
    src: string | { uri: string }
    alt: string,
    title: string,
    description: string
  },
  className?: ComponentProps<"div">["className"]
}

export default function ImageCard({ data, className }: ImageCardProps) {
  const [hovered, setHovered] = useState(false)

  return (
    <div 
      className={twMerge("relative cursor-pointer", className, hovered ? "scale-105":"scale-100")}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}  
    >
      <Image 
        src={data.src} 
        alt={data.alt} 
        className="w-full h-auto object-cover" 
        height={"100%"}
        width={"100%"}
      />

      <div className={twMerge("absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-end w-full p-4 bg-black bg-opacity-50 transition-all duration-300", hovered ? "opacity-100":"opacity-0")}>
        <h1 className="text-white font-urbanist">{data.title}</h1>
        <p className="text-white text-sm font-latoRegular">{data.description}</p>
      </div>
    </div> 
  )
}