import Category from "@/entities/Category";
import { createClient } from "@utils/supabase/client";

export const getAllCategories = async () => {
  const client = createClient();

  try {
    const { data, error } = await client.from("categories").select();

    if (data) {
      const categories = data.map((c: any) => {
        return new Category(c.id, c.name);
      });

      return { data: categories };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}