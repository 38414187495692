import { useEffect, useState } from "react";
import GridColumns from "./GridColums";
import Photo from "@/entities/Photo";

type Props = {
  photos: Photo[];
  isPublic?: boolean;
}

export default function GridContainer({ photos, isPublic }: Props) {
  const [columns, setColumns] = useState(0);

  useEffect(() => {
    if (!window) return;

    handleResize();

    window.addEventListener("resize", () => {
      handleResize()
    })

    return () => {
      // cleanup
      window.removeEventListener("resize", () => {
        handleResize()
      })
    }
  }, []);

  // Handlers
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setColumns(2);
    } else if (window.innerWidth < 1024) {
      setColumns(3);
    } else {
      setColumns(5);
    }
  }

  return (
    <div className="grid grid-rows-auto grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4 ">
      {
        columns > 0 && (
          <GridColumns columns={columns} photos={photos} isPublic={isPublic} />
        )
      }
    </div>
  )
}