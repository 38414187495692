import { getUserById } from "@/api/auth";
import { cdn } from "@/api/cdn";
import { getPhotoById } from "@/api/photos";
import { postRecentSearch } from "@/api/recent-search";
import Button from "@/components/atoms/Button";
import Icon from "@/components/atoms/Icon";
import Input from "@/components/atoms/Input";
import Select from "@/components/atoms/Select";
import DownloadDropdown from "@/components/molecules/DownloadDropdown";
import Photo from "@/entities/Photo";
import User from "@/entities/User";
import { PhotoActions, PhotoState } from "@/gx/signals/photos/photos";
import useLoadPhotos from "@/hooks/useLoadImage";
import useSearchPhotosByCategory from "@/hooks/useSearchByCategory";
import useSearchPhotosByQuery from "@/hooks/useSearchByText";
import GridContainer from "@components/molecules/GridContainer";
import { useActions, useSignal } from "@dilane3/gx";
import RImage from "@rasenganjs/image";
import { useNavigate, type PageComponent, useSearchParams } from "rasengan";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

type Props = {
  params: {
    id: string;
  };
};

const Gallery: PageComponent = ({ params: { id } }: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { category, query } = useMemo(() => {
    const category = params.get("category") || "";
    const query = params.get("query") || "";

    return { category, query };
  }, [params]);

  // Search by query
  const [pageQuery, setPageQuery] = useState(0);
  const [queryPhotos, setQueryPhotos] = useState<Photo[]>([]);

  // Search by categories
  const [pageCategory, setPageCategory] = useState(0);
  const [categoryPhotos, setCategoryPhotos] = useState<Photo[]>([]);

  const [allPhotos, setAllPhotos] = useState<Photo[]>([]);
  const [page, setPage] = useState(0);
  const [photo, setPhoto] = useState<Photo | null>(null);
  const [newPhoto, setNewPhoto] = useState<string>("");
  const [newWidth, setNewWidth] = useState<number>(0);
  const [newHeight, setNewHeight] = useState<number>(0);
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [propertiesChanged, setPropertiesChanged] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [properties, setProperties] = useState<{
    quality: number;
    width: number;
    height: number;
    fit: "cover" | "contain" | "fill";
  }>({
    quality: 75,
    width: 500,
    height: 500,
    fit: "cover",
  });

  const { result, loading } = useLoadPhotos(page);
  const { result: resultCategory, loading: loadingCategory } =
    useSearchPhotosByCategory(category, pageCategory);
  const { result: resultQuery, loading: loadingQuery } = useSearchPhotosByQuery(
    query,
    pageQuery
  );

  useEffect(() => {
    const handleGetNewImage = async () => {
      if (!photo) return;

      const newPhoto = await cdn.get(photo.src, properties);

      toast.success("Image has been processed successfully!");

      setPropertiesChanged(false);

      setNewPhoto(newPhoto);

      // access to width and height property of image
      const img = new Image();

      img.src = newPhoto;

      img.onload = async () => {
        setNewWidth(img.width);
        setNewHeight(img.height);
      };
    };

    if (propertiesChanged) {
      // Faire quelque chose
      handleGetNewImage();
    }
  }, [propertiesChanged]);

  useEffect(() => {
    const copy = allPhotos.map((p) => p);

    result.forEach((photo) => {
      const exist = copy.find((p) => p.id === photo.id);

      if (!exist) {
        copy.push(photo);
      }
    });

    setAllPhotos(copy);
  }, [result.length, result, loading]);

  useEffect(() => {
    const copy = categoryPhotos.map((p) => p);

    resultCategory.forEach((photo) => {
      const exist = copy.find((p) => p.id === photo.id);

      if (!exist) {
        copy.push(photo);
      }
    });

    setCategoryPhotos(copy);
  }, [resultCategory.length, resultCategory, loadingCategory]);

  useEffect(() => {
    const copy = queryPhotos.map((p) => p);

    resultQuery.forEach((photo) => {
      const exist = copy.find((p) => p.id === photo.id);

      if (!exist) {
        copy.push(photo);
      }
    });

    setQueryPhotos(copy);
  }, [resultQuery.length, resultQuery, loadingQuery]);

  useEffect(() => {
    const handleGetPhotoById = async () => {
      const { data } = await getPhotoById(id);

      if (data) {
        const { data: res } = await getUserById(data.author_id);

        if (res) {
          const author = new User({
            id: res.uid,
            name: res.name,
            email: res.email,
            avatar: res.avatar,
            username: res.username,
            color: res.color,
          });

          const photo = new Photo({
            id: data.id,
            title: data.title,
            description: data.description,
            url: data.url,
            categories: data.categories,
            width: data.width,
            height: data.height,
            mimeType: data.mime_type,
            author,
            createdAt: new Date(data.created_at),
            link: data.link,
          });

          setPhoto(photo);
          handleUpdateProperties("quality", 75);
          handleUpdateProperties("fit", "cover");
        }
      }
    };

    if (id) {
      handleGetPhotoById();
    }
  }, [id]);

  useEffect(() => {
    if (category) {
      setCategoryPhotos([]);
      setPageCategory(0);
    }
  }, [category]);

  useEffect(() => {
    (async () => {
      if (query) {
        setQueryPhotos([]);
        setPageQuery(0);

        // Get key
        let key = localStorage.getItem("recent-search-key");
        if (!key) {
          key = Math.random().toString(36).substring(10);
          localStorage.setItem("recent-search-key", key);
        }

        await postRecentSearch(key, query)
      }
    })()
  }, [query]);

  useEffect(() => {
    if (photo) {
      // access to width and height property of image
      const img = new Image();

      img.src = photo.src;

      img.onload = async () => {
        handleUpdateProperties("width", img.width);
        handleUpdateProperties("height", img.height);
        setNewWidth(img.width);
        setNewHeight(img.height);
      };
    }
  }, [photo]);

  const handleNextPage = () => {
    if (category) {
      setPageCategory(pageCategory + 1);
    } else if (query) {
      setPageQuery(pageQuery + 1);
    } else {
      setPage(page + 1);
    }
  };

  const handleUpdateProperties = (key: string, value: any) => {
    setProperties((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDownload = async (
    mimeType: "webp" | "png" | "jpg" | "gif" | "avif"
  ) => {
    setOpen(false);
    setDownloading(true);

    if (photo) {
      const uri = await cdn.get(photo.src, {
        ...properties,
        format: mimeType,
      });

      setDownloading(false);

      const a = document.createElement("a");

      a.href = uri;
      a.download = photo.title;
      a.click();
    }
  };

  return (
    <section className="pt-[80px] max-w-[1500px] min-w-[300px] w-[100vw] px-6 pb-10">
      {category && (
        <div className="py-8 flex items-center">
          <h1 className="text-lg font-urbanist">Result for category: </h1>
          <Button className="relative bg-[#f5f5f5] transition-all ml-4 pr-12">
            {category}

            <Icon
              name="x-circle-fill"
              className="absolute right-4 top-1/2 -translate-y-1/2 text-red-500"
              size={18}
              active={false}
              onClick={() => {
                navigate("/gallery");
              }}
            />
          </Button>
        </div>
      )}

      {query && (
        <div className="py-8 flex items-center">
          <h1 className="text-lg font-urbanist">Result for query: </h1>
          <Button className="bg-[#f5f5f5] transition-all ml-4">{query}</Button>
        </div>
      )}
      <GridContainer
        photos={category ? categoryPhotos : query ? queryPhotos : allPhotos}
        isPublic
      />

      <div className="flex w-full mt-12 justify-center">
        <Button onClick={handleNextPage} className="bg-primary text-white">
          {category
            ? loadingCategory
              ? "Loading..."
              : "Load More"
            : loading
            ? "Loading..."
            : "Load More"}
        </Button>
      </div>

      <section
        className={twMerge(
          "z-50 rounded-s-2xl rounded-e-2xl border-2 border-[#eee] fixed bottom-0 left-0 right-0 top-[80px] bg-white overflow-auto p-4 md:p-10 transition-all duration-300",
          id
            ? "opacity-100 z-10 translate-y-0"
            : "opacity-0 -z-10 translate-y-[100%]"
        )}
      >
        <div
          onClick={() => {
            navigate("/gallery", { replace: true });
            setPhoto(null);
            setNewPhoto("");
          }}
          className="absolute left-4 md:left-10 top-8 rounded-full w-12 h-12 flex items-center justify-center cursor-pointer bg-gray-200 hover:bg-gray-300 transition-all"
        >
          <Icon name="arrow-left" className="font-bold" />
        </div>

        <div className="flex flex-col lg:flex-row gap-4 mt-20 lg:mt-0 lg:mx-[100px] rounded-3xl overflow-hidden h-auto">
          <div
            className="w-full lg:w-[500px] border-[1px] rounded-3xl overflow-hidden"
            style={{
              height: 500 / ((photo?.width || 500) / (photo?.height || 600)),
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {photo ? (
              <RImage
                src={newPhoto ? newPhoto : photo.src}
                alt="Image"
                className="rounded-lg"
                width={newWidth > 500 ? "100%" : newWidth}
                height={
                  newWidth > 500
                    ? 500 / (newWidth / newHeight)
                    : newWidth / (newWidth / newHeight)
                }
              />
            ) : (
              <div className="w-full h-full bg-gray-400"></div>
            )}
          </div>

          <div className="w-full lg:w-[500px]">
            {photo && (
              <div>
                <div className="relative flex flex-col sm:flex-row sm:items-center justify-between mt-4">
                  <div className="flex items-center">
                    <RImage
                      src={photo.author.avatar}
                      alt="Avatar"
                      className="w-8 h-8 rounded-full"
                      width={40}
                      height={40}
                    />

                    <div className="ml-4">
                      <p className="text-sm font-bold">{photo.author.name}</p>
                      <p className="text-xs text-gray-500">
                        {photo.author.username}
                      </p>
                    </div>
                  </div>

                  <Button
                    onClick={() => setOpen(true)}
                    className="bg-primary text-white mt-4 sm:mt-0 w-full sm:w-auto"
                    // disabled={!newPhoto}
                  >
                    {downloading ? "Downloading..." : "Download"}
                  </Button>

                  <div
                    className={twMerge(
                      "absolute right-0 top-0 h-auto rounded-lg bg-white p-4 transition-all duration-150  border-[1px] border-[#eee] shadow-xl",
                      open
                        ? "opacity-100 z-20 translate-x-0"
                        : "opacity-0 -z-20 translate-x-[100%]"
                    )}
                    onMouseLeave={() => {
                      setOpen(false);
                    }}
                  >
                    <DownloadDropdown onClick={handleDownload} />
                  </div>
                </div>
                <h1 className="text-3xl font-bold mt-6">{photo.title}</h1>
                <p className="text-sm text-gray-500 mt-2">
                  {photo.description}
                </p>
              </div>
            )}

            <div className="mt-8 w-full">
              <h3 className="text-2xl font-bold font-urbanist">Operations</h3>

              <div className="mt-4">
                <Input
                  label={`Quality: ${properties.quality}%`}
                  onChange={(e) =>
                    handleUpdateProperties("quality", e.target.value)
                  }
                  value={properties.quality}
                  type="range"
                  min={5}
                  max={100}
                  className="h-2 w-full cursor-ew-resize appearance-none rounded-full focus:border-transparent focus:ring-transparent bg-gray-200 disabled:cursor-not-allowed outline-none p-0"
                />
              </div>
              <div className="mt-4">
                <Input
                  label={`Width`}
                  onChange={(e) =>
                    handleUpdateProperties("width", e.target.value)
                  }
                  value={properties.width}
                  type="number"
                />
              </div>
              <div className="mt-4">
                <Input
                  label={`Height`}
                  onChange={(e) =>
                    handleUpdateProperties("height", e.target.value)
                  }
                  value={properties.height}
                  type="number"
                />
              </div>
              <div className="mt-4">
                <Select
                  label={`Contain fit`}
                  placeholder="Select a fit"
                  options={[
                    {
                      value: "cover",
                      label: "Cover",
                    },
                    {
                      value: "contain",
                      label: "Contain",
                    },
                    {
                      value: "fill",
                      label: "Fill",
                    },
                  ]}
                  onChange={(e) =>
                    handleUpdateProperties("fit", e.target.value)
                  }
                  value={properties.fit}
                  type="number"
                />
              </div>

              <Button
                onClick={() => setPropertiesChanged(true)}
                className="bg-primary text-white mt-8"
              >
                {propertiesChanged ? "Processing..." : "Apply Changes"}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

Gallery.path = "/gallery/:id?";
Gallery.metadata = {
  title: "Gallery of photos",
  description: "Explore the range list of images"
}

export default Gallery;
