const baseURL = "https://cdn-magpies.netlify.app/.netlify/images";

type Options = {
  width?: number;
  height?: number;
  quality?: number;
  fit?: "cover" | "contain" | "fill";
  format?: "webp" | "png" | "jpg" | "gif" | "avif";
};

export const cdn = {
  get: async (image_uri: string, options: Options) => {
    let url = `${baseURL}/?url=${image_uri}`;

    if (options.width) {
      url += `&w=${options.width}`;
    }

    if (options.height) {
      url += `&h=${options.height}`;
    }

    if (options.quality) {
      url += `&q=${options.quality}`;
    }

    if (options.format) {
      url += `&fm=${options.format}`;
    }

    if (options.fit) {
      url += `&fit=${options.fit}`;
    }

    const res = await fetch(url);
    const blob = await res.blob();

    return URL.createObjectURL(blob);
  },
};