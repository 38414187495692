import { PageComponent, Link } from "rasengan";
import Hero from "../layout/components/Hero";
import Container from "@components/molecules/Container";
import ImageCard from "@components/molecules/ImageCard";
import SimpleCard from "@components/molecules/SimpleCard";
import Button from "@components/atoms/Button";
import Slider from "@components/molecules/Slider";
import ShowOnHoverContainer from "@components/molecules/ShowOnHoverContainer";
import useGetImageProperties from "@hooks/useGetImageProperties";
import { useSignal } from "@dilane3/gx";
import { AuthState } from "@gx/signals/auth";
import useSearchPhotosByCategory from "@/hooks/useSearchByCategory";

import image1 from "@assets/images/home/1.jpeg";
import image4 from "@assets/images/home/4.jpeg";
import image7 from "@assets/images/home/7.jpeg";
import image8 from "@assets/images/home/8.jpeg";
import image9 from "@assets/images/home/9.jpeg";
import image10 from "@assets/images/home/10.jpeg";
import image11 from "@assets/images/home/11.jpeg";
import image12 from "@assets/images/home/12.jpeg";
import image13 from "@assets/images/home/13.jpeg";
import image14 from "@assets/images/home/14.jpeg";

import landscape1 from "@assets/images/landscapes/1.jpeg";
import landscape2 from "@assets/images/landscapes/2.jpeg";
import landscape3 from "@assets/images/landscapes/3.jpeg";
import landscape4 from "@assets/images/landscapes/4.jpeg";
import landscape5 from "@assets/images/landscapes/5.jpeg";
import landscape6 from "@assets/images/landscapes/6.jpeg";
import landscape7 from "@assets/images/landscapes/7.jpeg";



const Home: PageComponent = () => {
  useGetImageProperties("https://picsum.photos/seed/1/800/500");

  const { user } = useSignal<AuthState>("auth");

  const { result: landscapes } = useSearchPhotosByCategory("landscape", 0, 5);
  const { result: animals } = useSearchPhotosByCategory("animals", 0, 10);

  return (
    <section className="w-full h-full">
      <Hero />

      <Container 
        title="Browse our gallery or share your own photos" 
        description="Have a look at our gallery or share your own photos with us"
      >
        <div className="grid example__template-grid">
          <ImageCard 
            data={{
              src: { uri: image7 },
              alt: "photo",
              title: "Beatiful Lanscape",
              description: ""
            }}
            className="a"
          />
          <ImageCard 
            data={{
              src: { uri: image1 },
              alt: "photo",
              title: "Autumn Lanscape",
              description: ""
            }}
            className="b"
          />
          <ImageCard 
            data={{
              src: { uri: image9 },
              alt: "photo",
              title: "Web UI",
              description: ""
            }}
            className="c"
          />
          <ImageCard 
            data={{
              src: { uri: image4 },
              alt: "photo",
              title: "MagPies Flying",
              description: ""
            }}
            className="d"
          />
          <ImageCard 
            data={{
              src: { uri: image8 },
              alt: "photo",
              title: "Beautiful African girl",
              description: ""
            }}
            className="e"
          />
          <ImageCard 
            data={{
              src: { uri: image10 },
              alt: "photo",
              title: "Village of Zambia",
              description: ""
            }}
            className="f"
          />
        </div>

        <div className="w-full flex flex-col sm:flex-row items-center justify-center gap-4 mx-auto mt-8">
          {
            user && (
              <Link to="/create">
                <Button className="text-black bg-[#f5f5f5] w-full xs:w-auto">Share your photos</Button>
              </Link>
            )
          }
          <Link to="/gallery">
            <Button className="text-white bg-primary w-full xs:w-auto">Explore gallery</Button>
          </Link>
        </div>
      </Container>

      <Container 
        title="Explore our categories" 
        description="Choose from a wide range of categories"
      >
        <Slider>
          <div className="slider__content flex items-start gap-4 mx-auto">
            <SimpleCard 
              data={{
                src: { uri: image4 },
                alt: "photo",
                link: "/gallery/?category=animals",
                title: "Animals",
                description: "There are many species"
              }}
            />
            <SimpleCard 
              data={{
                src: { uri: image8 },
                alt: "photo",
                link: "/gallery/?category=drawing",
                title: "Drawing",
                description: "Explore the world of drawings"
              }}
            />
            <SimpleCard 
              data={{
                src: { uri: image1 },
                alt: "photo",
                link: "/gallery/?category=landscape",
                title: "Landscape",
                description: "Discover beautiful landscapes"
              }}
            />
            <SimpleCard 
              data={{
                src: { uri: image9 },
                alt: "photo",
                link: "/gallery/?category=ui",
                title: "UI - UX Design",
                description: "Explore the world of UI - UX Design"
              }}
            />
            <SimpleCard
              data={{
                src: { uri: image11 },
                link: "/gallery/?category=programming",
                alt: "photo",
                title: "Programming",
                description: "Discover the world of programming"
              }}
            />
            <SimpleCard
              data={{
                src: { uri: image12 },
                link: "/gallery/?category=vehicle",
                alt: "photo",
                title: "Vehicles",
                description: "Look at the available vehicles"
              }}
            />
            <SimpleCard
              data={{
                src: { uri: image13 },
                link: "/gallery/?category=movies",
                alt: "photo",
                title: "Movies",
                description: "Check out movies display"
              }}
            />
            <SimpleCard
              data={{
                src: { uri: image14 },
                link: "/gallery/?category=sport",
                alt: "photo",
                title: "Sports",
                description: "Explore the world of the sport"
              }}
            />
          </div>
        </Slider>
      </Container>

      {/* <Container 
        title="Explore categories of animals" 
        description="Choose from a wide range of animal categorie"
      >
        <Slider>
          <div className="slider__content flex items-start gap-4 mx-auto">
            {
              // animals.map((animal, index) => (
              //   <SimpleCard 
              //     key={index}
              //     data={animal}
              //   />
              // ))
            }
          </div>
        </Slider>
      </Container> */}

      <Container 
        title="Beautiful Landscapes" 
        description="The world is very nice, so why not take a look at some beautiful landscapes"
      >
        <ShowOnHoverContainer 
          data={[
            {
              src: { uri: landscape2 },
              alt: "photo",
              title: "Beatiful view of nature",
              description: "Description 2"
            },
            {
              src: { uri: landscape4 },
              alt: "photo",
              title: "The nature is very nice, this mountain is so cool",
              description: "Description 4"
            },
            {
              src: { uri: landscape5 },
              alt: "photo",
              title: "Savannah landscape",
              description: "Description 5"
            },
            {
              src: { uri: landscape6 },
              alt: "photo",
              title: "Jungle",
              description: "Description 6"
            },
            {
              src: { uri: landscape7 },
              alt: "photo",
              title: "Nice sunset",
              description: "Description 7"
            }
          ]}
        />
      </Container>
    </section>
  );
}

Home.path = "/";
Home.metadata = {
  title: "Welcome to MagPies",
  description: "Explore and share images to the world",
};

export default Home;