import { useEffect, useState } from "react";
import { getRecentSearch } from '../api/recent-search/index';

export default function useGetRecentSearch() {
  const [recentSearch, setRecentSearch] = useState<Array<string>>([]);

  useEffect(() => {
    (async () => {
      // Get key
      let key = localStorage.getItem("recent-search-key");
      if (!key) {
        key = Math.random().toString(36).substring(10);
        localStorage.setItem("recent-search-key", key);
      }

      // Get recent search
      const { data } = await getRecentSearch(key);

      if (data) {
        setRecentSearch(data);
      }
    })();
  }, []);

  return { recentSearch };
}