import User from "@/entities/User";
import Avatar from "../atoms/Avatar";
import Icon from "../atoms/Icon";
import { authLogout } from "@api/auth";
import { Link } from "rasengan";

type Props = {
  user: User;
  onClose: () => void;
}

export default function ProfileDropdownContainer({ user, onClose }: Props) {
  // Handler
  const handleLogout = async () => {
    await authLogout();
    onClose();
  };

  return (
    <div className="w-[250px] h-auto rounded-lg bg-white p-0">
      <div className="flex items-center bg-[#F2F2F2] rounded-full p-2">
        <Avatar image={user.avatar} name={user.name} />

        <div className="ml-4 flex flex-col justify-center gap-2">
          <h3 className="text-md font-bold">{user.truncatedName}</h3>
          <p className="text-sm">{user.email.substring(0, 20) + "..."}</p>
        </div>
      </div>

      <div className="mt-4">
        <Link to="/profile">
          <div className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer">
            <Icon name="person" size={20} />
            <span className="ml-4">Profile</span>
          </div>
        </Link>

        <Link to="/create">
          <div className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer">
            <Icon name="plus-circle" size={20} />
            <span className="ml-4">Create Photo</span>
          </div>
        </Link>

        <div onClick={handleLogout} className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer">
          <Icon name="box-arrow-left" size={20} />
          <span className="ml-4">Sign out</span>
        </div>
      </div>
    </div>
  )
}