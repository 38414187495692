import { createClient } from "@utils/supabase/client";

export const createPhoto = async (data: {
  title: string;
  description: string;
  link?: string;
  url: string;
  categories: Array<string>;
  author_id: string;
  mime_type: string;
  width: number;
  height: number;
}) => {
  const client = createClient();

  try {
    const { data: res, error } = await client.from("images").insert([data]).select();

    if (res) {
      return { data: res[0] };
    }

    return { error };
  } catch (error) {
    return { error };
  }
};


// Get all photos with pagination
export const getPhotos = async (page: number, limit: number) => {
  const client = createClient();

  try {
    const { data, error } = await client
      .from("images")
      .select("*")
      .range(page, limit);

    if (data) {
      return { data };
    }

    return { error };
  } catch (error) {
    return { error };
  }
};

// Search photos with pagination
export const searchPhotos = async (query: string, page: number, limit: number) => {
  const client = createClient();

  try {
    // compare the query with the title and description
    // const { data, error } = await client
    //   .from("images")
    //   .select("*")
    //   .textSearch("title", query)
    //   .range(page, limit);

    // if (data) {
    //   return { data };
    // }

    const keys = query.split(" ");

    console.log(keys.join(" or "))

    const { data, error } = await client
      .from("images")
      .select("*")
      .textSearch("title", keys.join(" or "), {
        type: "websearch",
        config: "english",
      })
      .range(page, limit);

    if (data) {
      return { data };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}

// Get photo by id
export const getPhotoById = async (id: string) => {
  const client = createClient();

  try {
    const { data, error } = await client.from("images").select("*").eq("id", id);

    if (data) {
      return { data: data[0] };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}

// Get photos by category
export const getPhotosByCategory = async (category: string, page: number, limit: number) => {
  const client = createClient();

  try {
    const { data, error } = await client
      .from("images")
      .select("*")
      .contains('categories', [category])
      .range(page, limit);

    if (data) {
      return { data };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}

// Get photos by author
export const getPhotosByAuthor = async (author_id: string) => {
  const client = createClient();

  try {
    const { data, error } = await client
      .from("images")
      .select("*")
      .eq("author_id", author_id);

    if (data) {
      return { data };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}