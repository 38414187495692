import Image from "@rasenganjs/image";

type Props = {
  image: string;
  name: string;
  onClick?: () => void;
};

export default function Avatar({ image, name, onClick }: Props) {
  return (
    <div className="w-10 h-10" title={name} onClick={onClick}>
      <Image
        src={image}
        alt="Avatar"
        width={"100%"}
        height={"100%"}
        className="rounded-full"
      />
    </div>
  );
}
