import { ComponentProps } from "react"
import { twMerge } from "tailwind-merge"

type Props = {
  children: React.ReactNode
  className?: ComponentProps<"button">["className"],
} & ComponentProps<"button">

export default function Button({ children, onClick, className, ...rest }: Props) {
  return (
    <button onClick={onClick} className={twMerge("rounded-full py-2 px-6 font-bold font-latoRegular cursor-pointer text-md", className)} {...rest}>{children}</button>
  )
} 