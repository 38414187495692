import "@styles/index.css";
import "@styles/template.css";
import "@rasenganjs/image/lib/styles/index.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { type AppProps } from "rasengan";
import AppRouter from "@app/app.router";
import store from "./gx/store";
import GXProvider from "@dilane3/gx";
import { ToastContainer } from "react-toastify";

export default function App({ Component, children }: AppProps) {
  return (
    <GXProvider store={store}>
      <Component router={AppRouter}>
        {children}
      </Component>
    </GXProvider>
  );
}
