import { RouterComponent, defineRouter } from "rasengan";
import AuthLayout from "@app/auth/layout/auth.layout";
import Login from "@app/auth/pages/login.page";

class AuthRouter extends RouterComponent {}

export default defineRouter({
  imports: [],
  layout: AuthLayout,
  pages: [Login]
})(AuthRouter);