import Button from "@/components/atoms/Button";
import { AuthState } from "@/gx/signals/auth";
import { MyPhotoState } from "@/gx/signals/photos/myPhoto";
import GridContainer from "@components/molecules/GridContainer";
import { useSignal } from "@dilane3/gx";
import { useNavigate, type PageComponent, Link } from "rasengan";
import { useEffect } from "react";

const Profile: PageComponent = () => {
  const navigate = useNavigate();

  const { user } = useSignal<AuthState>("auth");
  const { photos } = useSignal<MyPhotoState>("myPhotos");

  // Effects
  useEffect(() => {
    if (!user) {
      navigate("/gallery", { replace: true });
    }
  }, [])

  if (!user) return null;

  return (
    <section className="pt-[80px] max-w-[1500px] min-w-[300px] w-[100vw] px-6 pb-10">
      <div className="w-full mt-8 rounded-lg border-[1px] border-[#eee] p-4 mb-8">
        {/* Profile */}
        <div className="flex items-center gap-4">
          <img
            src={user.avatar}
            alt={user.name}
            className="w-[100px] h-[100px] rounded-full"
          />

          <div className="flex flex-col gap-2">
            <h2 className="text-xl font-bold">{user.name}</h2>
            <p className="text-sm">{user.email}</p>
          </div>
        </div>
      </div>

      <div className="py-4 w-full flex justify-between items-center">
        <h1 className="text-2xl md:text-[30px] font-urbanist">Your Photos</h1>

        <Link to="/create">
          <Button className="text-white bg-primary">Add Photo</Button>
        </Link>
      </div>

      {
        photos.length === 0 ? (
          <div className="flex justify-center items-center h-[200px]">
            <p className="text-lg">No photos found</p>
          </div>
        ) : (
          <GridContainer photos={photos} />
        )
      }
    </section>
  )
};

Profile.path = "/profile"
Profile.metadata = {
  title: "Profile",
  description: "Your profile"
}

export default Profile;