import { createClient } from "@utils/supabase/client";

export const uploadPhoto = async (file: File) => {
  const client = createClient();

  try {
    const filename = `${Date.now()}-${file.name.split(".").pop()}`;
    const { data, error } = await client.storage
      .from("images")
      .upload(`public/${filename}`, file);

    if (data) {
      return { data };
    }

    return { error };
  } catch (error) {
    return { error };
  }
};
