import { createClient as createBrowserClient } from "@supabase/supabase-js";

const { RASENGAN_SUPABASE_URL, RASENGAN_SUPABASE_ANON_KEY } = import.meta.env;

const client = createBrowserClient(
  RASENGAN_SUPABASE_URL!,
  RASENGAN_SUPABASE_ANON_KEY!,
  {
  }
)

export const createClient = () => client;
