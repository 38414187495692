import { getPhotosByAuthor } from "@/api/photos";
import Photo from "@/entities/Photo";
import { AuthState } from "@/gx/signals/auth";
import { MyPhotoActions } from "@/gx/signals/photos/myPhoto";
import { useActions, useSignal } from "@dilane3/gx";
import { useEffect } from "react";

export default function useLoadMyPhotos() {
  const { loadPhotos } = useActions<MyPhotoActions>("myPhotos");
  const { user } = useSignal<AuthState>("auth");

  useEffect(() => {
    if (!user) return;

    (async () => {
      const { data } = await getPhotosByAuthor(user.id);
      
      if (data) {
        const photos = data.map(res => new Photo({
          id: res.id,
          title: res.title,
          description: res.description,
          url: res.url,
          categories: res.categories,
          width: res.width,
          height: res.height,
          mimeType: res.mime_type,
          author: user,
          createdAt: new Date(res.created_at),
          link: res.link
        }));

        loadPhotos(photos);
      }
    })();
  }, [user]);
}