export default class Category {
  private _id: string;
  private _name: string;

  constructor(id: string, name: string) {
    this._id = id;
    this._name = name;
  }

  // Getters
  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  static fromJson(data: any): Category {
    return new Category(data.id, data.name);
  }

  toJson(): any {
    return {
      id: this._id,
      name: this._name,
    };
  }
}