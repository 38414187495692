import User from "@entities/User";
import { createSignal } from "@dilane3/gx";

export interface AuthState {
  user: User | null;
  loading: boolean;
}

export interface AuthActions {
  login: (user: User) => void;
  logout: () => void;
}

export default createSignal<AuthState>({
  name: "auth",
  state: {
    user: null,
    loading: true,
  },
  actions: {
    login: (state, user: User) => {
      state.user = user;
      state.loading = false;

      return state;
    },

    logout: (state) => {
      state.user = null;

      return state;
    },
  }
})