import { ComponentProps, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import Image from "@rasenganjs/image";
import Photo from "@/entities/Photo";
import { AuthState } from "@/gx/signals/auth";
import { useSignal } from "@dilane3/gx";
import { Link, useNavigate } from "rasengan";

type ImageCardProps = {
  data: Photo;
  columns: number;
  className?: ComponentProps<"div">["className"];
  isPublic?: boolean;
};

export default function ImageCardAuthored({
  data,
  columns,
  className,
  isPublic = false,
}: ImageCardProps) {
  const navigate = useNavigate();
  
  const [hovered, setHovered] = useState(false);

  const { user } = useSignal<AuthState>("auth");

  const author = useMemo(() => {
    return data.author;
  }, [user]);

  const columnWidth = useMemo(() => {
    const windowWidth = window.innerWidth;

    if (columns === 2) {
      return windowWidth / 2;
    }

    if (columns === 3) {
      return windowWidth / 3;
    }

    return windowWidth / 5;
  }, []);

  return (
    // <Link to={`/gallery/${data.id}`}>
    <div onClick={() => navigate(`/gallery/${data.id}`)}>
      <div
        className={twMerge(
          "relative cursor-pointer overflow-hidden rounded-xl",
          className
        )}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          height: columnWidth / (data.width / data.height),
        }}
      >

        <Image
          src={data.src}
          alt={data.title}
          className="w-full h-auto object-cover"
          height={"100%"}
          width={"100%"}
        />

        <div
          className={twMerge(
            "absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-end w-full p-4 bg-black bg-opacity-50 transition-all duration-300",
            hovered ? "opacity-100" : "opacity-0"
          )}
        >
          {
            data.link && (
              <div
                className={twMerge(
                  "absolute top-2 left-2 z-20  p-2 py-1 rounded-full border-[2px] border-white"
                )}
              >
                <Link to={data.link} target="_blank">
                  <p className="text-white text-sm font-urbanist font-extrabold text-ellipsis overflow-hidden text-nowrap whitespace-nowrap w-[130px] max-w-full">
                    {data.link.split("https://")[1]}
                  </p>
                </Link>
              </div>
            )
          }

          <h1 className="text-white font-urbanist">{data.title}</h1>
          <p className="text-white text-sm font-latoRegular">
            {data.description}
          </p>
        </div>
      </div>

      <div className="flex items-center mt-2">
        {isPublic && author && (
          <div className="flex items-center">
            <Image
              src={author.avatar}
              alt={author.name}
              width={40}
              height={40}
              className="w-8 h-8 object-cover rounded-full"
            />
            <p className="text-xs lg:text-md font-bold text-gray-800 ml-2">
              {author.truncatedName}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
