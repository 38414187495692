type Props = {
  children: React.ReactNode
}

export default function Main({ children }: Props) {
  return (
    <main className="w-full mx-auto overflow-x-hidden">
      {children}
    </main>
  )
}