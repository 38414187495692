import Photo from "@/entities/Photo";
import ImageCardAuthored from "./ImageCardAuthored";
import { useMemo } from "react";

type Props = {
  columns?: number;
  photos: Photo[];
  isPublic?: boolean;
};

export default function GridColumns({ columns = 2, photos, isPublic }: Props) {
  const [col1, col2, col3, col4, col5] = useMemo(() => {
    if (!photos) return [[], [], [], [], []];
    // dispatch photos into columns

    // 2 columns
    if (columns === 2) {
      const dispatchedPhotos: Array<Array<Photo>> = [[], [], [], [], []];

      photos.forEach((photo, index) => {
        if (index % 2 === 0) {
          dispatchedPhotos[0].push(photo);
        } else {
          dispatchedPhotos[1].push(photo);
        }
      });

      return dispatchedPhotos;
    }

    // 3 columns
    if (columns === 3) {
      const dispatchedPhotos: Array<Array<Photo>> = [[], [], [], [], []];

      photos.forEach((photo, index) => {
        if (index % 3 === 0) {
          dispatchedPhotos[0].push(photo);
        } else if (index % 3 === 1) {
          dispatchedPhotos[1].push(photo);
        } else {
          dispatchedPhotos[2].push(photo);
        }
      });

      return dispatchedPhotos;
    }

    // 5 columns
    const dispatchedPhotos: Array<Array<Photo>> = [[], [], [], [], []];

    photos.forEach((photo, index) => {
      if (index % 5 === 0) {
        dispatchedPhotos[0].push(photo);
      } else if (index % 5 === 1) {
        dispatchedPhotos[1].push(photo);
      } else if (index % 5 === 2) {
        dispatchedPhotos[2].push(photo);
      } else if (index % 5 === 3) {
        dispatchedPhotos[3].push(photo);
      } else {
        dispatchedPhotos[4].push(photo);
      }
    });

    return dispatchedPhotos;
  }, [photos, columns]);

  return (
    <>
      {columns === 2 && (
        <>
          <div className="flex flex-col gap-8">
            {col1.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col2.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
        </>
      )}

      {columns === 3 && (
        <>
          <div className="flex flex-col gap-8">
            {col1.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col2.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col3.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
        </>
      )}

      {columns === 5 && (
        <>
          <div className="flex flex-col gap-8">
            {col1.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col2.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col3.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col4.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8">
            {col5.map((photo, index) => (
              <div className="rounded-2xl w-full" key={index}>
                <ImageCardAuthored data={photo} className="h-auto" columns={columns} isPublic={isPublic} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
