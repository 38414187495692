import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  label?: string;
  children?: React.ReactNode;
  placeholder?: string;
  className?: ComponentProps<"textarea">["className"];
} & ComponentProps<"textarea">;

export default function TextArea({
  label = "",
  children,
  placeholder = "",
  className,
  ...rest
}: Props) {
  return (
    <div className="relative w-full">
      {
        label && (
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            {label}
          </label>
        )
      }
      <textarea
        placeholder={placeholder}
        className={twMerge(
          "w-full px-4 py-2 rounded-2xl bg-[#f5f5f5] border-[1px] border-gray-300 focus:border-primary hover:bg-[#eee] transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 cursor-default focus:cursor-text",
          className
        )}
        rows={4}
        {...rest}
      ></textarea>

      {children}
    </div>
  );
}
