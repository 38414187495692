import Icon from "../atoms/Icon";

type Props = {
  onClick: (format: "webp" | "png" | "jpg" | "gif" | "avif") => void;
};

export default function DownloadDropdown({ onClick }: Props) {
  return (
    <div className="w-[200px] h-auto rounded-lg bg-white p-0">
      <div className="mt-0">
        <div
          onClick={() => onClick("png")}
          className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer"
        >
          <span className="ml-0">Download PNG</span>
        </div>

        <div
          onClick={() => onClick("jpg")}
          className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer"
        >
          <span className="ml-0">Download JPG</span>
        </div>

        <div
          onClick={() => onClick("webp")}
          className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer"
        >
          <span className="ml-0">Download WEBP</span>
        </div>

        <div
          onClick={() => onClick("avif")}
          className="hover:bg-[#f5f5f5] p-2 rounded-lg flex items-center cursor-pointer"
        >
          <span className="ml-0">Download AVIF</span>
        </div>
      </div>
    </div>
  );
}
