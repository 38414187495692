import React, { useEffect, useMemo } from "react";
import { Outlet, LayoutComponent, useLocation } from "rasengan";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import { createClient } from "@utils/supabase/client";
import User from "@entities/User";
import * as nookies from "nookies";
import { getCurrentUser } from "@api/auth";
import { AuthActions, AuthState } from "@gx/signals/auth";
import { useActions, useSignal } from "@dilane3/gx";
import { Session } from "@supabase/supabase-js";
import { ToastContainer } from "react-toastify";
import useLoadMyPhotos from "@/hooks/useLoadMyPhoto";
import useLoadPhotos from "@/hooks/useLoadImage";

const AppLayout: LayoutComponent = () => {
  const location = useLocation();

  const { login, logout } = useActions<AuthActions>("auth");
  const { user: currentUser } = useSignal<AuthState>("auth");

  const [session, setSession] = React.useState<Session | null>(null);
  const [event, setEvent] = React.useState("");

  const isTransparent = useMemo(() => {
    return location.pathname === "/";
  }, [location.pathname]);

  useLoadMyPhotos();

  useEffect(() => {
    const client = createClient();

    client.auth.onAuthStateChange(async (event, session) => {
      setSession(session);
      setEvent(event);
    });
  }, []);

  useEffect(() => {
    if (event !== "SIGNED_OUT") {
      if (!session) return;
    }

    (async () => {
      if (event === "SIGNED_IN") {
        nookies.setCookie(
          null,
          "sb-access-token",
          session?.access_token as string,
          {
            maxAge: 30 * 24 * 60 * 60 * 1000,
            path: "/",
            sameSite: "none",
            secure: true,
          }
        );
  
        const { data } = await getCurrentUser(session?.access_token as string);
  
        if (data) {
          const user = new User({
            id: data.uid,
            email: data.email as string,
            name: data.name,
            avatar: data.avatar,
            username: data.username,
            color: data.color,
          });

  
          login(user);
        }
      } else if (event === "SIGNED_OUT") {
        nookies.destroyCookie(null, "sb-access-token");
        setSession(null);
  
        logout();
      } else if (event === "INITIAL_SESSION") {
        const cookies = nookies.parseCookies();
  
        const token = cookies["sb-access-token"];
  
        if (token) {
          const { data } = await getCurrentUser(token);
  
          if (data) {
            const user = new User({
              id: data.uid,
              email: data.email as string,
              name: data.name,
              avatar: data.avatar,
              username: data.username,
              color: data.color,
            });

            login(user);
          }
        }
      }
    })();
  }, [session]);

  return (
    <section className="mx-auto">
      <Navbar transparent={isTransparent} />

      <Main>
        <Outlet />

        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />  
      </Main>
    </section>
  );
};

AppLayout.path = "/";

AppLayout.loader = async () => {
  const client = createClient();

  // Check if authenticated
  const session = await client.auth.getSession();

  return {
    props: {},
  };
};

export default AppLayout;
