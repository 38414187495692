import { RouterComponent, defineRouter } from "rasengan";
import Home from "@app/pages/home.page";
import Gallery from "@app/pages/gallery.page";
import Create from "@app/pages/create.page";
import Profile from "@app/pages/profile.page";
import AppLayout from "@app/layout/app.layout";
import AuthRouter from "@app/auth/auth.router";

class AppRouter extends RouterComponent {}

export default defineRouter({
  imports: [AuthRouter],
  layout: AppLayout,
  pages: [Home, Gallery, Create, Profile],
})(AppRouter);
