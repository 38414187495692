import Photo from "@entities/Photo";
import { createSignal } from "@dilane3/gx";

export interface PhotoState {
  photos: Photo[];
  page: number;
  end: boolean;
  loading?: boolean;
  search: string;
}

export interface PhotoActions {
  loadPhotos: (photos: Photo[]) => void;
  nextPage: () => void;
}

export default createSignal<PhotoState>({
  name: "photos",
  state: {
    photos: [],
    page: 1,
    end: false,
    loading: true,
    search: "",
  },
  actions: {
    loadPhotos: (state, photos: Photo[]) => {
      photos.forEach(photo => {
        // Check if photo exist
        const exist = state.photos.find(p => p.id === photo.id);

        if (!exist) {
          state.photos.push(photo);
        }
      });  

      state.loading = false;
    
      return state;
    },

    nextPage: (state) => {
      state.page = state.page + 1;
      state.loading = true;

      return state;
    }
  }
})