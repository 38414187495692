import Button from "@components/atoms/Button";
import Icon from "@components/atoms/Icon";
import Input from "@components/atoms/Input";
import { useEffect, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useSignal } from "@dilane3/gx";
import { AuthState } from "@gx/signals/auth";
import Avatar from "@components/atoms/Avatar";
import { Link, useNavigate, useSearchParams } from "rasengan";
import ProfileDropdownContainer from "@components/molecules/ProfileDropdownContainer";
import Category from "@/entities/Category";
import { getAllCategories } from "@/api/categories";
import { toast } from "react-toastify";
import useGetRecentSearch from "@/hooks/useGetRecentSearch";

type Props = {
  transparent?: boolean;
};

export default function Navbar({ transparent = true }: Props) {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { recentSearch } = useGetRecentSearch();

  const inputSearchRef = useRef<HTMLInputElement>(null);

  const { category, query } = useMemo(() => {
    const category = params.get("category") || "";
    const query = params.get("query") || "";

    return { category, query };
  }, [params]);

  const { user } = useSignal<AuthState>("auth");

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [dropdownHovered, setDropdownHovered] = useState(false);
  const [search, setSearch] = useState(query);

  const [categories, setCategories] = useState<Array<Category>>([]);

  useEffect(() => {
    if (category || query) {
      setIsFocused(false);
      setIsHovered(false);
    }

    if (query) {
      setSearch(query);
    }
  }, [category, query]);

  useEffect(() => {
    (async () => {
      const { data: categories } = await getAllCategories();

      if (categories) {
        setCategories(categories);
      } else {
        toast.error("Unable to fetch categories. Please refresh.");
      }
    })();
  }, []);

  useEffect(() => {
    if (!window) return;

    const handleScroll = () => {
      const transparent = window.location.pathname === "/";

      if (transparent) {
        const hero = document.querySelector(".hero");
        const navbar = document.querySelector(".navbar");
        const logoText = document.querySelector(".logo-text");

        if (
          hero &&
          navbar &&
          window.scrollY + navbar.clientHeight > hero.clientHeight
        ) {
          navbar?.classList.add("bg-white");
          navbar?.classList.remove("bg-transparent");
          logoText?.classList.add("text-primary");
          logoText?.classList.remove("text-white");
        } else {
          navbar?.classList.add("bg-transparent");
          navbar?.classList.remove("bg-white");
          logoText?.classList.remove("text-primary");
          logoText?.classList.add("text-white");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handler
  const handleEnterPress = (e: any) => {
    e.preventDefault();

    if (e.key === "Enter") {
      if (search) {
        setIsFocused(false);
        navigate(`/gallery?query=${search}`);
      }
    }
  };

  return (
    <header
      className={twMerge(
        "navbar z-30 fixed top-0 left-0 w-full h-[80px] inset-0 bg-cover bg-center backdrop-filter bg-opacity-50 backdrop-blur-md",
        transparent ? "bg-transparent" : "bg-white"
      )}
    >
      <section className="max-w-[1500px] min-w-[300px] w-full h-[80px] flex items-center justify-between py-4 px-4 md:px-10 lg:px-20 mx-auto gap-4 md:gap-8 ">
        <Link to="/">
          <span
            className={twMerge(
              "logo-text font-urbanist text-xl md:text-4xl",
              transparent ? "text-white" : "text-primary"
            )}
            translate="no"
          >
            MagPies
          </span>
        </Link>

        {user && (
          <Link to="/create">
            <Button className="bg-[#f5f5f5] hidden sm:block hover:bg-primary hover:text-white transition-all">
              Create
            </Button>
          </Link>
        )}

        <div className="relative w-full">
          <Input
            ref={inputSearchRef}
            placeholder="Search"
            className="pl-12"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyUp={handleEnterPress}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
            <Icon
              name="search"
              className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500"
              size={18}
              active={false}
            />

            {query && (
              <Icon
                name="x-circle-fill"
                className="absolute right-4 top-1/2 -translate-y-1/2 text-red-500"
                size={18}
                active={false}
                onClick={() => {
                  setSearch("");
                  navigate("/gallery");
                }}
              />
            )}
          </Input>

          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={twMerge(
              "z-20 fixed lg:absolute left-4 right-4 lg:left-0 lg:right-0 top-[80px] lg:top-[50px] min-h-300px rounded-2xl bg-white p-8 transition-all duration-300",
              isFocused || isHovered
                ? "opacity-100 z-20 -translate-y-0"
                : "opacity-0 -z-20 -translate-y-[100%]"
            )}
          >
            {
              recentSearch.length > 0 && (
                <div className="w-full mb-4 gap-2">
                  <h1 className="text-lg font-urbanist mb-4">Recent search</h1>

                  <div>
                    {recentSearch.map((search) => (
                      <Link to={`/gallery?query=${search}`}>
                        <Button className="bg-[#f5f5f5] hover:bg-primary hover:text-white transition-all">
                          {search}
                        </Button>
                      </Link>
                    ))}
                  </div>
                </div>
              )
            }

            <div className="w-full">
              <h1 className="text-lg font-urbanist mb-4">Categories</h1>

              <div>
                {categories.map((category) => (
                  <Link to={`/gallery?category=${category.name}`}>
                    <Button className="hover:bg-[#f5f5f5] transition-all">
                      {category.name}
                    </Button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="relative min-w-[80px]">
          {user ? (
            <>
              <Avatar
                image={user.avatar}
                name={user.name}
                onClick={() => {
                  setOpen(true);
                  setDropdownHovered(true);
                }}
              />

              <div
                className={twMerge(
                  "absolute right-0 top-0 h-auto rounded-lg bg-white p-4 transition-all duration-150  border-[1px] border-[#eee] shadow-xl",
                  open
                    ? "opacity-100 z-20 translate-x-0"
                    : "opacity-0 -z-20 translate-x-[100%]"
                )}
                onMouseEnter={() => setDropdownHovered(true)}
                onMouseLeave={() => {
                  setDropdownHovered(false);
                  setOpen(false);
                }}
              >
                <ProfileDropdownContainer
                  user={user}
                  onClose={() => setOpen(false)}
                />
              </div>
            </>
          ) : (
            <Link to="/auth/login">
              <Button className="text-white bg-primary">Login</Button>
            </Link>
          )}
        </div>
      </section>

      <div
        className={twMerge(
          "z-10 fixed bottom-0 left-0 right-0 top-[80px] bg-black/60 w-full h-[100vh] transition-all duration-300",
          isFocused || isHovered
            ? "opacity-100 z-10 translate-y-0"
            : "opacity-0 -z-10 translate-y-[100%]"
        )}
      ></div>
    </header>
  );
}
