import { useEffect, useState } from "react";

export default function useGetImageProperties(uri: string) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = uri;
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });
    };
  }, [uri]);

  return dimensions;
}
