import { AuthState } from "@gx/signals/auth";
import Button from "@components/atoms/Button";
import { useSignal } from "@dilane3/gx";
import Image from "@rasenganjs/image";
import { Link } from "rasengan";
import { cdn } from "@api/cdn";
import { useState, useEffect } from "react";

// Images
import image1 from "@assets/images/home/1.jpeg";
import image2 from "@assets/images/home/2.jpeg";
import image3 from "@assets/images/home/3.jpeg";
import image4 from "@assets/images/home/4.jpeg";
import image5 from "@assets/images/home/5.jpeg";
import image6 from "@assets/images/home/6.jpeg";

export default function Hero() {
  const { user } = useSignal<AuthState>("auth");

  const [image, setImage] = useState<string>("");

  useEffect(() => {
    (async () => {
      const url = await cdn.get("https://picsum.photos/seed/25/1400/2300", { width: 2400, height: 2300 });
      setImage(url);
    })()
  }, [])
  
  return (
    <section className="hero area xl:max-h-[800px] min-h-[500px] py-[80px] h-auto ">
      <section className="max-w-[1500px] min-w-[300px] mx-auto w-full h-auto lg:h-full">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <div className="w-full h-full mt-4 flex flex-col-reverse xl:flex-row items-center px-4 md:px-10 lg:px-20">
          <div className="z-10 w-full flex flex-col items-center mt-12 xl::mt-0 xl:items-start">
            <h1 className="text-[50px] font-urbanist text-primary bg-white hover:text-white hover:bg-primary transition-all cursor-pointer my-1 py-1 px-1 text-center xl:text-start">MagPies</h1>
            <p className="text-4xl font-urbanist text-white text-center xl:text-start">Explore the world in images</p>
            <p className="font-latoRegular mt-2 text-white text-center xl:text-start">Find, discover the image you want.</p>

            <div className="flex items-center mt-4 gap-2">
              {
                user ? (
                  <Link to="/create">
                    <Button className="text-black bg-white">Create</Button>
                  </Link>
                ):(
                  <Link to="/auth/login">
                    <Button className="text-black bg-white">Join us</Button>
                  </Link>
                )
              }
              <Link to="/gallery">
                <Button className="text-white bg-primary">Explore gallery</Button>
              </Link>
            </div>
          </div>

          <div className="template-grid mx-auto w-auto">
            <div className="b pic overflow-hidden">
              <Image 
                src={{ uri: image3 }}
                alt="photo"
                className="w-full h-full object-cover"
                width={"100%"}
                height="100%"
              />
            </div>
            <div className="d pic overflow-hidden">
              <Image 
                src={{ uri: image2 }}
                alt="photo"
                className="w-full h-auto object-cover"
                width={"100%"}
                height="100%"
              />
            </div>
            <div className="e pic overflow-hidden">
              <Image 
                src={{ uri: image1 }}
                alt="photo"
                className="w-full h-auto object-cover"
                width={"100%"}
                height="100%"
              />
            </div>
            <div className="f pic overflow-hidden">
              <Image 
                src={{ uri: image4 }}
                alt="photo"
                className="w-full h-auto object-cover"
                width={"100%"}
                height="100%"
              />
            </div>
            <div className="g pic overflow-hidden">
              <Image 
                src={{ uri: image5 }}
                alt="photo"
                className="w-full h-auto object-cover"
                width={"100%"}
                height="100%"
              />
            </div>
            <div className="h pic overflow-hidden">
              <Image 
                src={{ uri: image6 }}
                alt="photo"
                className="w-full object-cover"
                width={"100%"}
                height="100%"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}