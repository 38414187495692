const baseURL = 'https://magpies.dilane3.com';

/**
 * Get recent search
 */
export const getRecentSearch = async (key: string) => {
  try {
    const response = await fetch(`${baseURL}/recent-search/?key=${key}`);
    const data = await response.json();

    return { data };
  } catch (error) {
    return { error };
  }
}


/**
 * Post recent search
 */
export const postRecentSearch = async (key: string, value: string) => {
  const formData = new FormData();
  formData.append('key', key);
  formData.append('value', value);

  try {
    const response = await fetch(`${baseURL}/recent-search`, {
      method: 'POST',
      body: formData
    });
    const data = await response.json();

    return { data };
  } catch (error) {
    return { error };
  }
}