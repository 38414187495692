import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  label?: string;
  options?: Array<{ label: string, value: any }>;
  children?: React.ReactNode;
  type?: string;
  placeholder?: string;
  className?: ComponentProps<"select">["className"];
} & ComponentProps<"select">;

export default function Select({
  label = "",
  children,
  placeholder = "",
  className,
  ...rest
}: Props) {
  return (
    <div className="relative w-full">
      {
        label && (
          <label className="block text-sm font-semibold text-gray-700 mb-2">
            {label}
          </label>
        )
      }
      <select
        className={twMerge(
          "w-full px-4 py-2 rounded-full bg-[#f5f5f5] border-[1px] border-gray-300 focus:border-primary hover:bg-[#eee] transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 cursor-default focus:cursor-text",
          className
        )}
        {...rest}
      >
        <option value="" disabled hidden className="text-gray-400">
          {placeholder}
        </option>
        {rest.options?.map((option) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {children}
    </div>
  );
}
