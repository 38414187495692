import Photo from "@entities/Photo";
import { createSignal } from "@dilane3/gx";

export interface MyPhotoState {
  photos: Photo[];
}

export interface MyPhotoActions {
  addPhoto: (photo: Photo) => void;
  loadPhotos: (photos: Photo[]) => void;
}

export default createSignal<MyPhotoState>({
  name: "myPhotos",
  state: {
    photos: [],
  },
  actions: {
    addPhoto: (state, photo: Photo) => {
      // Check if photo exist
      const exist = state.photos.find(p => p.id === photo.id);

      if (!exist) {
        state.photos.push(photo);
      }

      return state;
    },

    loadPhotos: (state, photos: Photo[]) => {
      photos.forEach(photo => {
        // Check if photo exist
        const exist = state.photos.find(p => p.id === photo.id);

        if (!exist) {
          state.photos.push(photo);
        }
      }); 

      return state;
    },
  }
})