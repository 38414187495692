import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Image from "@rasenganjs/image"
import Button from "../atoms/Button";

type Props = {
  data: Array<{
    src: string | { uri: string }
    alt: string,
    title: string,
    description: string
  }>
}

export default function ShowOnHoverContainer({ data }: Props) {
  const [current, setCurrent] = useState(2);

  const handleHover = (pos: number) => {
    setCurrent(pos);
  }

  return (
    <div className="slider__content flex items-center gap-2 md:gap-4 mx-auto w-full">
      {
        data.map((image, index) => (
          <div 
            key={index}
            onMouseEnter={() => handleHover(index)}
            className={twMerge("relative h-[200px] md:h-[340px] rounded-lg overflow-hidden transition-all duration-300", current === index ? "w-full":"w-[10%]")}
          >
            <Image 
              src={image.src} 
              alt={image.alt} 
              className="w-full object-cover" 
              height={"100%"}
              width={"100%"}
            />

            <div className={twMerge("absolute bottom-0 left-0 top-0 right-0 flex flex-col justify-end w-full p-4 bg-black bg-opacity-50 transition-all duration-300", current === index ? "opacity-100":"opacity-0")}>
              <h1 className="text-white font-urbanist">{image.title}</h1>
              {/* <p className="text-white text-sm font-latoRegular">{image.description}</p> */}

              {/* <Button className="bg-primary text-white mt-4 sm:w-[200px]">Open</Button> */}
            </div>
          </div>
        ))
      }
    </div>
  );
}
