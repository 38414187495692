import { getUserById } from "@/api/auth";
import { getPhotos } from "@/api/photos";
import Photo from "@/entities/Photo";
import User from "@/entities/User";
import { useEffect, useState, useTransition } from "react";

export default function useLoadPhotos(page: number, limit = 15) {
  // const { page } = useSignal<PhotoState>("photos");
  // const { loadPhotos } = useActions<PhotoActions>("photos");

  const [_, transition] = useTransition();

  const [photos, setPhotos] = useState<Photo[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPhotos([]);

    (async () => {
      await handleLoad();
    })();
  }, [page]);

  const handleLoad = async () => {
    setLoading(true);

    const { data } = await getPhotos(page * limit, page * limit + limit);

    if (data) {
      const photos: Photo[] = [];

      for (let p of data) {
        // Get author
        const { data: res } = await getUserById(p.author_id);

        if (res) {
          const author = new User({
            id: res.uid,
            name: res.name,
            email: res.email,
            avatar: res.avatar,
            username: res.username,
            color: res.color,
          });

          const photo = new Photo({
            id: p.id,
            title: p.title,
            description: p.description,
            url: p.url,
            categories: p.categories,
            width: p.width,
            height: p.height,
            mimeType: p.mime_type,
            author,
            createdAt: new Date(p.created_at),
            link: p.link
          });

          photos.push(photo);
        }
      }

      transition(() => {
        setPhotos(photos);

        setLoading(false);
      });
    }
  };

  return { result: photos, loading };
}
