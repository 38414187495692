import { getRandomColor } from "@utils/colors";
import { createClient } from "@utils/supabase/client";

// import { createClient } from "@supabase/supabase-js";

export const authLogin = async () => {
  const client = createClient();

  // Google login
  try {
    const { error } = await client.auth.signInWithOAuth({ provider: "google", options: {
      scopes: "profile email",
      redirectTo: "http://192.168.43.30:5320/"
    }})

    if (error) console.error("Error logging in:", error.message);
  } catch (error) {
    console.log(error);
  }
};

export const authLogout = async () => {
  const client = createClient();

  try {
    const { error } = await client.auth.signOut();

    if (error) console.error("Error logging out:", error.message);
  } catch (error) {
    console.log(error);
  }
}

export const getCurrentUser = async (token: string) => {
  const client = createClient();

  try {
    const { data, error } = await client.auth.getUser(token);

    if (data && data.user) {
      const { email, avatar_url, name } = data.user.user_metadata;

      const payload = {
        email,
        username: email.split("@")[0],
        name,
        avatar: avatar_url,
        color: getRandomColor(),
      }

      const { data: user } = await insertUser(payload);

      return { data: user };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}

export const insertUser = async (user: {
  email: string;
  username: string;
  name: string;
  avatar: string;
  color: string;
}) => {
  const client = createClient();

  try {
    const { data: res } = await checkUser(user.email);

    if (!res) {
      const { data, error } = await client.from("users").insert(user);
  
      if (data) {
        return { data: data[0] };
      }
  
      return { error };
    }

    return { data: res };
  } catch (error) {
    return { error };
  }
}

export const checkUser = async (email: string) => {
  const client = createClient();

  try {
    client.from("users").select("*").eq("email", email).then(console.log)

    const { data, error } = await client.from("users").select("*").eq("email", email);

    if (data) {
      return { data: data[0] };
    }

    return { error };
  } catch (error) {
    console.log(error)
    return { error };
  }
}

export const getUserByEmail = async (email: string) => {
  const client = createClient();

  try {
    const { data, error } = await client.from("users").select("*").eq("email", email)

    if (data) {
      return { data: data[0] };
    }

    return { error };

  } catch (error) {
    return { error };
  }
}

export const getUserById = async (id: "string") => {
  const client = createClient();

  try {
    const { data, error } = await client.from("users").select("*").eq("uid", id);

    if (data) {
      return { data: data[0] };
    }

    return { error };
  } catch (error) {
    return { error };
  }
}
