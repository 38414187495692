import { defineConfig } from "rasengan";

export default defineConfig({
  reactStrictMode: false,

  server: {
    production: {
      // hosting: "vercel",
    }
  },

  // Define aliases
  vite: {
    resolve: {
      alias: [
        {
          find: "@app",
          replacement: "./src/app",
        },
        {
          find: "@components",
          replacement: "./src/components",
        },
        {
          find: "@assets",
          replacement: "./src/assets",
        },
        {
          find: "@styles",
          replacement: "./src/styles",
        },
        {
          find: "@gx",
          replacement: "./src/gx",
        },
        {
          find: "@hooks",
          replacement: "./src/hooks",
        },
        {
          find: "@entities",
          replacement: "./src/entities",
        },
        {
          find: "@utils",
          replacement: "./src/utils",
        },
        {
          find: "@api",
          replacement: "./src/api",
        },
      ],
    },
  },
});
