import { LayoutComponent, Outlet } from "rasengan";
import React from "react";

const AuthLayout: LayoutComponent = () => {
  return (
    <section className="auth-layout mx-auto overflow-auto lg:overflow-hidden min-h-[100vh] w-full flex items-center justify-center p-8">
      <Outlet />

      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </section>
  )
}

AuthLayout.path = "/auth";

export default AuthLayout;