interface IUser {
  id: string;
  email: string;
  name: string;
  username: string;
  avatar: string;
  color: string;
}

export default class User {
  private _id: string;
  private _email: string;
  private _name: string;
  private _username: string;
  private _avatar: string;
  private _color: string;

  constructor(data: IUser) {
    this._id = data.id;
    this._email = data.email;
    this._name = data.name;
    this._username = data.username;
    this._avatar = data.avatar;
    this._color = data.color;
  }

  get id() {
    return this._id;
  }

  get email() {
    return this._email;
  }

  get name() {
    return this._name;
  }

  get username() {
    return this._username;
  }

  get avatar() {
    return this._avatar;
  }

  get color() {
    return this._color;
  }

  get truncatedName() {
    const names = this._name.split(' ');

    if (names.length === 1) {
      return names[0];
    }

    return `${names[0]} ${names[names.length - 1]}`;
  }

  static fromJSON(data: IUser) {
    return new User(data);
  }

  toJSON() {
    return {
      id: this._id,
      email: this._email,
      username: this._username,
      avatar: this._avatar,
      name: this._name,
      color: this._color,
    };
  }
}