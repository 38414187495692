import { imageBaseURL } from "@/api";
import User from "./User";

interface IPhoto {
  id: number;
  title: string;
  description?: string;
  url: string;
  categories: string[];
  width: number;
  height: number;
  mimeType: string;
  author: User;
  createdAt: Date;
  link?: string;
}

export default class Photo {
  private _id: number;
  private _title: string;
  private _description?: string;
  private _url: string;
  private _categories: string[];
  private _width: number;
  private _height: number;
  private _mimeType: string;
  private _author: User;
  private _createdAt: Date;
  private _link?: string;

  constructor(data: IPhoto) {
    this._id = data.id;
    this._title = data.title;
    this._description = data.description;
    this._url = data.url;
    this._categories = data.categories;
    this._width = data.width;
    this._height = data.height;
    this._mimeType = data.mimeType;
    this._author = data.author;
    this._createdAt = data.createdAt;
    this._link = data.link || undefined;
  }

  // Getters
  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get url() {
    return this._url;
  }

  get categories() {
    return this._categories;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get mimeType() {
    return this._mimeType;
  }

  get author() {
    return this._author;
  }

  get createdAt() {
    return this._createdAt;
  }

  get src() {
    return `${imageBaseURL}${this._url}`
  }

  get link() {
    return this._link;
  }

  static fromJSON(data: IPhoto) {
    return new Photo(data);
  }

  toJSON() {
    return {
      id: this._id,
      title: this._title,
      description: this._description,
      url: this._url,
      categories: this._categories,
      width: this._width,
      height: this._height,
      mimeType: this._mimeType,
      createdAt: this._createdAt,
      author: this._author,
      link: this._link
    };
  }
}