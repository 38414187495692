import { Link, PageComponent } from "rasengan";
import image1 from "@assets/images/1.png";
import image2 from "@assets/images/2.png";
import Image from "@rasenganjs/image";
import Button from "@components/atoms/Button";
import { authLogin } from "@api/auth";

const Login: PageComponent = () => {
  return (
    <section className="z-10 max-w-[1500px] min-w-[300px] lg:min-w-[1000px] min-h-[200px] rounded-3xl mx-auto max-h-[500px] lg:h-auth-container flex flex-col lg:flex-row bg-white md:overflow-auto overflow-hidden">
      <div className="w-full lg:w-1/2 lg:h-full p-4 py-10 md:p-8 lg:p-12 flex flex-col items-center lg:items-start bg-white lg:bg-[#4a13a8]">
        <h1 className="text-3xl md:text-4xl font-urbanist lg:text-white text-center md:text-start">Welcome to <span className="text-primary bg-white hover:text-white hover:bg-primary transition-all cursor-default">MagPies</span></h1>
        <p className="text-gray-800 lg:text-white mt-2 text-center lg:text-start px-10 lg:px-0">
          Join us, explore images and share your own to the world
        </p>

        <Image 
          src={image2}
          alt="Login picture"
          width="100%"
          height="auto"
          className="mt-4 hidden lg:block"
        />
      </div>
      <div className="w-full lg:w-1/2 lg:h-full flex flex-col mt-8 lg:mt-0">
        {/* Login with google only */}
        <div className="flex flex-col items-center justify-center lg:h-full">
          <h1 className="text-2xl font-urbanist text-primary">Login to MagPies</h1>

          <div className="flex flex-col-reverse lg:flex-row gap-4 mt-8 items-center">
            <Link to="/gallery">
              <Button className="bg-[#f5f5f5] text-black px-8 py-2">Explore gallery</Button>
            </Link>
            <Button className="bg-primary text-white px-8 py-2" onClick={authLogin}>Login with Google</Button>
          </div>
        </div> 

        {/* Adding footer */}
        <div className="flex items-center justify-center h-16 text-black">
          <p className="text-sm">© 2024 MagPies. All rights reserved.</p>
        </div>
      </div>
    </section>
  )
}

Login.path = "/login";
Login.metadata = {
  title: "Login to MagPies",
  description: "Join us at MagPies!",
}

export default Login;