import { uploadPhoto } from "@api/upload";
import Icon from "@components/atoms/Icon";
import Button from "@components/atoms/Button";
import Input from "@components/atoms/Input";
import Select from "@components/atoms/Select";
import TextArea from "@components/atoms/TextArea";
import RImage from "@rasenganjs/image";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "rasengan";
import { useActions, useSignal } from "@dilane3/gx";
import { AuthState } from "@gx/signals/auth";
import { imageBaseURL } from "@/api";
import { createPhoto } from "@/api/photos";
import Photo from "@/entities/Photo";
import { MyPhotoActions } from "@/gx/signals/photos/myPhoto";
import Category from "@/entities/Category";
import { getAllCategories } from "@/api/categories";

const Create = () => {
  const navigate = useNavigate();

  const inputFileRef = useRef<HTMLInputElement>(null);

  // Global state
  const { user } = useSignal<AuthState>("auth")
  const { addPhoto } = useActions<MyPhotoActions>("myPhotos")

  // Local state
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<{
    title: string;
    description: string;
    categories: Array<string>;
    link: string;
  }>({
    title: "",
    description: "",
    categories: [],
    link: "",
  });

  // Effects
  useEffect(() => {
    if (!user) {
      navigate("/gallery", { replace: true });
    }
  }, [])

  useEffect(() => {
    (async () => {
      const { data: categories, error } = await getAllCategories();

      if (categories) {
        setCategories(categories);
      } else {
        toast.error("Unable to fetch categories. Please refresh.");
      }
    })();
  }, []);

  // Handlers
  const handleUpdateData = (key: string, value: string, remove = false) => {
    setData((prev) => {
      if (key === "categories") {
        if (remove) {
          return ({ ...prev, [key]: prev.categories.filter(c => c !== value) });
        }

        // Check if not exist
        if (!prev.categories.includes(value)) {
          return ({ ...prev, [key]: [...prev.categories, value] });
        }

        return prev;
      }

      return ({ ...prev, [key]: value });
    });
  }

  // Handle file upload
  const handleOpenFileExplorer = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    e.dataTransfer.dropEffect = "copy";
    const file = e.dataTransfer.files[0];

    if (file) {
      setFile(file);
    }

    const uploadContainer = document.querySelector(".upload-container");

    if (uploadContainer) {
      uploadContainer.classList.remove("bg-primary-rgba/80");
    }
  };

  const previewFile = (file: File) => {
    return URL.createObjectURL(file);
  };

  const handleUploadFile = async () => {
    if (!file) return { error: "Provide a file" };

    // Upload file to the server
    const { data } = await uploadPhoto(file);

    if (data) {
      return { data };
    }

    return {
      error: "Unable to upload file. Please try again."
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formError = verifyForm();

    if (formError.error) {
      toast.error(formError.error);

      return;
    }

    if (!user) return;
    if (loading) return;

    setLoading(true);

    toast.info("Uploading file...");

    // Upload file to the server
    const { data: res, error } = await handleUploadFile();

    if (res) {
      const { path } = res;

      // access to width and height property of image
      const img = new Image();

      img.src = `${imageBaseURL}${path}`;

      img.onload = async () => {
        const payload = {
          title: data.title,
          description: data.description,
          link: data.link,
          categories: data.categories,
          url: path,
          width: img.width,
          height: img.height,
          mime_type: file?.type as string,
          author_id: user?.id as string,
        }

        // create photo
        const { data: res } = await createPhoto(payload);

        if (res) {
          toast.success("Photo created successfully");

          const photo = new Photo({
            id: res.id,
            title: res.title,
            description: res.description,
            url: res.url,
            categories: res.categories,
            width: res.width,
            height: res.height,
            mimeType: res.mime_type,
            author: user,
            createdAt: new Date(res.created_at),
            link: res.link
          });

          addPhoto(photo);

          navigate("/gallery", { replace: true });
        }

        setLoading(false);
      }
    }

    if (error) {
      toast.error("Unable to upload file. Please try again.");

      setLoading(false);
    }
  }

  const verifyForm = () => {
    if (!file) {
      return {
        error: "Please select a file"
      }
    }

    if (!data.title) {
      return {
        error: "Please enter a title"
      }
    }

    if (data.categories.length === 0) {
      return {
        error: "Please select a category"
      }
    }

    if (data.link !== "") {
      // check the link format
      const linkFormat = /^((http|https):\/\/)/;

      if (!linkFormat.test(data.link)) {
        return {
          error: "Please enter a valid link"
        }
      }
    }

    return {
      error: null
    }
  }

  const handleBack = () => {
    navigate("/gallery", { replace: true });
  }

  if (!user) return null;

  return (
    <section className="pt-[80px] max-w-[1500px] min-w-[300px] w-full h-[100vh] py-4 px-4 md:px-10 lg:px-20 mx-auto flex flex-col md:flex-row">
      <div
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();

          e.dataTransfer.dropEffect = "copy";

          const uploadContainer = document.querySelector(".upload-container");

          if (uploadContainer) {
            uploadContainer.classList.add("bg-primary-rgba/80");
          }
        }}
        onDrop={handleDragOver}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const uploadContainer = document.querySelector(".upload-container");

          if (uploadContainer) {
            uploadContainer.classList.remove("bg-primary-rgba/80");
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="h-auto md:h-full w-full max-w-full md:max-w-[400px] md:p-8"
      >
        {file ? (
          <div className="relative w-full h-auto rounded-xl overflow-hidden">
            <RImage
              src={previewFile(file)}
              alt="photo"
              className="w-full h-full object-cover "
              width={"100%"}
              height={"100%"}
            />

            {/* Display file info */}
            <div className="text-black mt-2">
              <p className="text-sm font-normal">
                {file.name} - {Math.floor((file.size / 1000000)*100)/100} MB  
                {" "}
                {/* Add dot character */}
                &middot;
                {" "}
                {/* Add format */}
                {file.type.split("/")[1].toLocaleUpperCase()}
              </p>
            </div>

            <div
              onClick={() => setFile(null)}
              className="absolute right-2 top-2 cursor-pointer w-8 h-8 rounded-full bg-red-500 flex items-center justify-center"
            >
              <Icon name="x" className="text-white" />
            </div>
          </div>
        ) : (
          <div
            onClick={handleOpenFileExplorer}
            className="upload-container  relative bg-primary-rgba/20 rounded-xl h-auto py-4 md:py-0 md:h-full max-h-[500px] w-full border-dashed border-2 border-primary"
          >
            <div className="h-full flex items-center justify-center flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-cloud-arrow-up-fill h-12 w-12 text-primary"
                viewBox="0 0 16 16"
              >
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z" />
              </svg>

              <p className="text-black text-lg font-normal mt-2">
                Select a file or drag
              </p>

              <small className="lg:absolut lg:bottom-0 text-center py-4 px-2">
                We recommend uploading a high-resolution photo that is at least
                1920px wide and 1080px tall. Only JPG, PNG, GIF, and TIFF files
                are supported up to 16MB.
              </small>
            </div>
          </div>
        )}

        <input
          ref={inputFileRef}
          type="file"
          hidden
          onChange={handleFileChange}
        />
      </div>

      <div className="h-auto md:h-full w-full pt-8 md:pt-0 pb-8">
        <form
          action=""
          className="md:border-l-[1px] border-l-[#eee] md:p-8 md:pr-0"
        >
          <Input label="Title *" placeholder="Enter title" className="mb-4" 
            value={data.title}
            onChange={(e) => handleUpdateData("title", e.target.value)}
          />

          <TextArea
            label="Description"
            placeholder="Enter description"
            className="mb-4"
            value={data.description}
            onChange={(e) => handleUpdateData("description", e.target.value)}
          />

          <Select
            label="Categories *"
            placeholder="Select a category"
            value={""}
            onChange={(e) => handleUpdateData("categories", e.target.value)}
            options={categories.map(c => ({ value: c.name, label: c.name }))}
            className="mb-4"
          />

          <div className="flex items-center mb-4">
            {
              data.categories.map((category, index) => (
                <span key={index} onClick={() => handleUpdateData("categories", category, true)} className="bg-primary cursor-pointer text-white px-2 py-1 rounded-full text-sm mr-2">
                  {category}
                </span>
              ))
            }
          </div>

          <Input label="Link" placeholder="Enter a link" className="mb-4"
            value={data.link}
            onChange={(e) => handleUpdateData("link", e.target.value)}
          />

          <div className="flex gap-4 mt-8">
            <Button className="bg-[#eee] text-black py-2 px-8" onClick={handleBack}>Cancel</Button>
            <Button className="bg-primary text-white py-2 px-8" disabled={!!verifyForm().error} onClick={handleSubmit}>
              {loading ? "Creating..." : "Create"}
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

Create.path = "/create";
Create.metadata = {
  title: "Create photo",
  description: "Share your photos with the world",
};

export default Create;
